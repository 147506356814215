import React, { useEffect, useState } from "react";
import { Offcanvas, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApiHook } from "../../hooks/apiHook";
import { useQueryClient } from "@tanstack/react-query";
import SubmitButton from "../Common/buttons/SubmitButton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { reverseNumberDisplay } from "../../utils/currencyNumberDisplay";
import CurrencyInput from "react-currency-input-field";
import { useForm } from "react-hook-form";
import { TripOriginSharp } from "@mui/icons-material";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
const { Dragger } = Upload;

const AddCampaigncanvas = ({ show, handleClose }) => {
    const { t } = useTranslation();
    const {
        register,
        watch,
        setValue,
        reset,
        trigger,
        formState: { errors },
    } = useForm({});

    const [formType, setFormType] = useState(0);
    const [fileList, setFileList] = useState(null);

    const uploadProps = {
        name: "file",
        multiple: false,
        showUploadList: false,
        beforeUpload: (file) => {
            const acceptedTypes = [
                "application/vnd.ms-excel", // .xls
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
                "text/csv", // .csv
            ];

            if (!acceptedTypes.includes(file.type)) {
                message.error(`${file.name} is not a valid spreadsheet file`);
                return Upload.LIST_IGNORE;
            }
            setFileList(file);
            return false;
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (status === "done") {
                message.success(
                    `${info.file.name} file uploaded successfully.`
                );
            } else if (status === "error") {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };
    const formValues = watch();
    const addCampaignMutation = ApiHook.CallCampaignAddSingle();
    const addCampaignFileMutation = ApiHook.CallCampaignAddFile();
    const queryClient = useQueryClient();

    const genderOptions = [
        {
            value: "m",
            label: "Male",
        },
        {
            value: "f",
            label: "Female",
        },
        {
            value: "o",
            label: "Others",
        },
    ];
    const handleSubmit = async (e) => {
        let isValid = await trigger();
        e.preventDefault();
        if (isValid) {
            addCampaignMutation.mutateAsync(formValues, {
                onSuccess: (res) => {
                    if (res?.status) {
                        toast.success("Campaign added successfully");
                        handleClose();
                        reset();
                        queryClient.invalidateQueries({
                            queryKey: ["campaign"],
                        });
                        queryClient.invalidateQueries({
                            queryKey: ["campaings-tiles"],
                        });
                    } else {
                        toast.error(t(res?.data?.description));
                    }
                },
            });
        }
    };

    const genderChange = (gender) => {
        setValue("gender", gender);
    };

    const handleSubmitFile = async (e) => {
        e.preventDefault();
        if (fileList) {
            const formData = new FormData();
            formData.append("file", fileList);
            addCampaignFileMutation.mutateAsync(formData, {
                onSuccess: (res) => {
                    if (res?.status) {
                        toast.success("Campaigns added successfully");
                        handleClose();
                        reset();
                        setFileList(null);
                        queryClient.invalidateQueries({
                            queryKey: ["campaign"],
                        });
                        queryClient.invalidateQueries({
                            queryKey: ["campaings-tiles"],
                        });
                    } else {
                        toast.error(t(res?.data?.description));
                    }
                },
            });
        }
    };

    const sizeConverter = (size) => {
        if (size < 1024) {
            return `${size}B`;
        } else if (size < Math.pow(1024, 2)) {
            return `${(size / 1024).toFixed(1)} KB`;
        } else if (size < Math.pow(1024, 3)) {
            return `${(size / Math.pow(1024, 2)).toFixed(1)} MB`;
        }
    };

    const downloadTemplate = (e) => {
        e.preventDefault();
        let element = document.getElementById("linkToTemplate");
        element?.click();
    };

    return (
        <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            id="ewalletTrnsfer"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("campaignAddForm")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button
                            className={`nav-link ${!formType && "active"}`}
                            id="file-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#file"
                            type="button"
                            role="tab"
                            aria-controls="file"
                            aria-selected="true"
                            onClick={() => {
                                setFormType(0);
                            }}
                        >
                            {t("fileUpload")}
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button
                            className={`nav-link ${formType && "active"}`}
                            id="single-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#single"
                            type="button"
                            role="tab"
                            aria-controls="single"
                            aria-selected="false"
                            onClick={() => {
                                setFormType(1);
                            }}
                        >
                            {t("addSingleCampaign")}
                        </button>
                    </li>
                </ul>
                <div
                    className="tab-content"
                    id="myTabContent"
                    style={{ marginTop: "10px" }}
                >
                    <div
                        className={`tab-pane fade ${
                            !formType && "active show"
                        }`}
                        id="file"
                        role="tabpanel"
                        aria-labelledby="file-tab"
                    >
                        <Form>
                            <Form.Group className="mb-3">
                                <div className="upload-file-header">
                                    <a
                                        href="/CampaignAddTemplate.xlsx"
                                        download
                                        id="linkToTemplate"
                                        style={{ display: "none" }}
                                    ></a>
                                    <button
                                        className="btn btn-sm upload-file-header-button"
                                        onClick={downloadTemplate}
                                    >
                                        <i className="fa-solid fa-download"></i>{" "}
                                        {t(`downloadDemoTemplate`)}
                                    </button>
                                </div>
                                <Form.Label>{t("uploadFile")} *</Form.Label>

                                <Dragger {...uploadProps}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        {t("draggerTitle")}
                                    </p>
                                    <p className="ant-upload-hint">
                                        {t("draggerSubTitle")}
                                    </p>
                                </Dragger>

                                {fileList && (
                                    <div className="show-campaign-file">
                                        <div>
                                            <div className="show-campaign-file-body">
                                                <i
                                                    className="fa-solid fa-file-excel"
                                                    style={{ fontSize: "25px" }}
                                                ></i>
                                            </div>
                                            <div className="show-campaign-file-details">
                                                <span>{fileList?.name}</span>
                                                <span style={{ color: "grey" }}>
                                                    Size:{" "}
                                                    {sizeConverter(
                                                        fileList?.size
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="show-campaign-file-delete">
                                            <i
                                                class="fa-solid fa-trash"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setFileList(null);
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                            <SubmitButton
                                click={handleSubmitFile}
                                text="submit"
                                className={"btn btn-primary submit_btn"}
                            />
                        </Form>
                    </div>
                    <div
                        className={`tab-pane fade ${formType && "active show"}`}
                        id="single"
                        role="tabpanel"
                        aria-labelledby="single-tab"
                    >
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("firstname")} *</Form.Label>
                                <Form.Control
                                    id="name"
                                    type="text"
                                    placeholder="Firstname"
                                    {...register("firstName", {
                                        required: t("this_field_is_required"),
                                        pattern: {
                                            value: /^[A-Za-z0-9]+(\s+[A-Za-z0-9]+)*$/,
                                            message: t("invalid_format"),
                                        },
                                    })}
                                    required
                                    isInvalid={errors?.firstName}
                                />
                                {errors.firstName && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.firstName.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("lastname")} *</Form.Label>
                                <Form.Control
                                    id="name"
                                    type="text"
                                    placeholder="Lastname"
                                    {...register("lastName", {
                                        required: t("this_field_is_required"),
                                        pattern: {
                                            value: /^[A-Za-z0-9]+(\s+[A-Za-z0-9]+)*$/,
                                            message: t("invalid_format"),
                                        },
                                    })}
                                    required
                                    isInvalid={errors?.lastName}
                                />
                                {errors.lastName && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.lastName.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    {t("sponserUsername")} *
                                </Form.Label>
                                <Form.Control
                                    id="sponserUsername"
                                    type="text"
                                    placeholder="Sponser Username"
                                    {...register("sponserUserName", {
                                        required: t("this_field_is_required"),
                                        pattern: {
                                            value: /^[A-Za-z0-9]+$/,
                                            message: t("invalid_format"),
                                        },
                                    })}
                                    required
                                    isInvalid={errors?.sponserUserName}
                                />
                                {errors.sponserUserName && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.sponserUserName.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("phoneNo")} *</Form.Label>
                                <Form.Control
                                    id="phone"
                                    type="text"
                                    placeholder="Phone Number"
                                    {...register("phone", {
                                        required: t("this_field_is_required"),
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: t("invalid_format"),
                                        },
                                    })}
                                    required
                                    isInvalid={errors?.phone}
                                />
                                {errors.phone && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.phone?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("email")} *</Form.Label>
                                <Form.Control
                                    id="email"
                                    type="text"
                                    placeholder="Email"
                                    {...register("email", {
                                        required: t("this_field_is_required"),
                                        pattern: {
                                            value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                            message: t("invalid_format"),
                                        },
                                    })}
                                    isInvalid={errors?.email}
                                    required
                                />
                                {errors.email && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.email?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("gender")} </Form.Label>
                                <select
                                    id={"editGender"}
                                    name={"editGender"}
                                    className={`form-control ${
                                        errors["gender"] ? "error-field" : ""
                                    }`}
                                    {...register("gender", {
                                        required: false,
                                    })}
                                    value={formValues?.gender ?? ""}
                                    onChange={async (e) => {
                                        const inputValue = e.target.value; // Get the current value of the input field
                                        genderChange(inputValue);
                                        await trigger("gender");
                                    }}
                                >
                                    <option value={""}>
                                        {"Select gender"}
                                    </option>
                                    {genderOptions?.map(
                                        (option, optionIndex) => (
                                            <option
                                                key={optionIndex}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )
                                    )}
                                </select>
                                {errors.gender && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.lastName.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <SubmitButton
                                click={handleSubmit}
                                text="submit"
                                className={"btn btn-primary submit_btn"}
                            />
                        </Form>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default AddCampaigncanvas;
