import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CurrencyConverter from "../../Currency/CurrencyConverter";
const EwalletTiles = ({ tiles, currency, conversionFactor }) => {
    const { t } = useTranslation();
    const moduleStatus = useSelector(
        (state) => state.dashboard?.appLayout?.moduleStatus
    );

    const numberDisplay = (num) => {
        if (isNaN(num)) return "0";
        if (num === 0) return "0";

        let number = Math.abs(num);
        if (number < 1e3) return number.toFixed(0);
        if (number < 1e6) return (number / 1e3).toFixed(2) + "k";
        if (number < 1e9) return (number / 1e6).toFixed(2) + "m";
        if (number >= 1e9) return (number / 1e9).toFixed(2) + "B";
    };

    return (
        <div className="ewallet_top_cnt_sction">
            <div className="row">
                <div className="col-xl-3 col-md-12">
                    <div className="ewallet_top_qc_balance_box">
                        <div className="row align-items-center">
                            <div className="ewallet_top_qc_balance_box_cnt">
                                <div className="ewallet_top_qc_balance_box_cnt_ico">
                                    <img
                                        src={"/images/ewallet-ico2.png"}
                                        alt=""
                                    />
                                </div>
                                <div className="ewallet_top_qc_balance_box_cnt_cnt">
                                    <div className="ewallet_top_qc_balance_box_cnt_head">
                                        {t("pendingCount")}
                                    </div>
                                    <div className="box_amnt_dv">
                                        <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                                            {numberDisplay(tiles?.pendingCount)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-12">
                    <div className="ewallet_top_qc_balance_box">
                        <div className="row align-items-center">
                            <div className="ewallet_top_qc_balance_box_cnt">
                                <div className="ewallet_top_qc_balance_box_cnt_ico">
                                    <img
                                        src={"/images/approved-ico.svg"}
                                        alt=""
                                    />
                                </div>
                                <div className="ewallet_top_qc_balance_box_cnt_cnt">
                                    <div className="ewallet_top_qc_balance_box_cnt_head">
                                        {t("completedCount")}
                                    </div>
                                    <div className="box_amnt_dv">
                                        <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                                            {numberDisplay(
                                                tiles?.completedCount
                                            )}
                                        </div>
                                        <div className="ewallt_top_comparison">
                                            {t("lastMonth")}
                                            <span
                                                className={
                                                    tiles?.creditSign === "up"
                                                        ? "cmprson_up"
                                                        : "cmprson_down"
                                                }
                                            >
                                                {`${
                                                    tiles?.creditSign === "up"
                                                        ? "+"
                                                        : "-"
                                                }${
                                                    tiles?.creditPercentage ??
                                                    "0"
                                                }% `}
                                                <strong>
                                                    <i
                                                        className={
                                                            tiles?.creditSign ===
                                                            "up"
                                                                ? "fa fa-arrow-up"
                                                                : "fa fa-arrow-down"
                                                        }
                                                    ></i>
                                                </strong>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EwalletTiles;
