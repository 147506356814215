import React, { useState } from "react";
import CampaignTiles from "../../components/Campaign/CampaignTiles";
import CampaignTable from "../../components/Campaign/CampaignTable";
import { NavLink } from "react-router-dom";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "antd";
import AddCampaigncanvas from "../../components/Campaign/AddCampaigncanvas";

const MainCampaignLayout = () => {
    const [showAddCampaign, setShowAddCampaign] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = useTranslation();

    const handleAddCampaign = () => {
        setShowAddCampaign((showAddCampaign) => !showAddCampaign);
    };
    const campaign = ApiHook.CallCampaignTiles();
    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const moduleStatus = useSelector(
        (state) => state.dashboard?.appLayout?.moduleStatus
    );

    return (
        <>
            <div className="page_head_top">{t("campaign")}</div>
            <div className="ewallet_top_btn_sec">
                <div className="row justify-content-between">
                    <div className="col-md-4 text-end ">
                        <div className="top_right_pop_btn_position">
                            {" "}
                            <Button
                                onClick={handleAddCampaign}
                                className="top_righ_pop_btn"
                            >
                                {t(`add_campaign_data`)}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <CampaignTiles
                tiles={campaign?.data}
                currency={userSelectedCurrency}
                conversionFactor={conversionFactor}
            />
            <CampaignTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currency={userSelectedCurrency}
            />
            <AddCampaigncanvas
                show={showAddCampaign}
                handleClose={handleAddCampaign}
            />
        </>
    );
};

export default MainCampaignLayout;
