import React, { useEffect, useState } from "react";
import { Offcanvas, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApiHook } from "../../hooks/apiHook";
import { useQueryClient } from "@tanstack/react-query";
import SubmitButton from "../Common/buttons/SubmitButton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { reverseNumberDisplay } from "../../utils/currencyNumberDisplay";
import CurrencyInput from "react-currency-input-field";
import { useForm } from "react-hook-form";
import { TripOriginSharp } from "@mui/icons-material";

const CampaignOffcanvas = ({ show, handleClose, data }) => {
    const { t } = useTranslation();
    const {
        register,
        watch,
        setValue,
        reset,
        trigger,
        formState: { errors },
    } = useForm({});
    let genderValue = {
        male: "m",
        female: "f",
        others: "o",
    };
    useEffect(() => {
        if (!!data) {
            reset({
                firstName: data[0]?.firstName,
                lastName: data[0]?.lastName,
                phone: data[0]?.phone,
                sponserUserName: data[0]?.sponserUserName,
                email: data[0]?.email,
                currentId: data[0]?.id,
                gender: genderValue[data[0]?.gender],
                dob: data[0]?.dob,
            });
        }
    }, [data]);
    const formValues = watch();
    const editCampaignMutation = ApiHook.CallCampaignEdit();
    const queryClient = useQueryClient();

    const genderOptions = [
        {
            value: "m",
            label: "Male",
        },
        {
            value: "f",
            label: "Female",
        },
        {
            value: "o",
            label: "Others",
        },
    ];

    const handleSubmit = async (e) => {
        let isValid = await trigger();
        e.preventDefault();
        if (isValid) {
            editCampaignMutation.mutateAsync(formValues, {
                onSuccess: (res) => {
                    console.log(res, "22222222222222222222222222");
                    if (res?.status) {
                        toast.success(t(res?.data));
                        handleClose();
                        queryClient.invalidateQueries({
                            queryKey: ["campaign"],
                        });
                        queryClient.invalidateQueries({
                            queryKey: ["campaings-tiles"],
                        });
                    }
                },
            });
        }
    };

    const genderChange = (gender) => {
        setValue("gender", gender);
    };

    return (
        <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            id="ewalletTrnsfer"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("campaignEditForm")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("firstname")} *</Form.Label>
                        <Form.Control
                            id="name"
                            type="text"
                            placeholder="Firstname"
                            {...register("firstName", {
                                required: t("this_field_is_required"),
                                pattern: {
                                    value: /^[A-Za-z0-9]+(\s+[A-Za-z0-9]+)*$/,
                                    message: t("invalid_format"),
                                },
                            })}
                            required
                            isInvalid={errors?.firstName}
                        />
                        {errors.firstName && (
                            <Form.Control.Feedback type="invalid">
                                {errors?.firstName.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("lastname")} *</Form.Label>
                        <Form.Control
                            id="name"
                            type="text"
                            placeholder="Lastname"
                            {...register("lastName", {
                                required: t("this_field_is_required"),
                                pattern: {
                                    value: /^[A-Za-z0-9]+(\s+[A-Za-z0-9]+)*$/,
                                    message: t("invalid_format"),
                                },
                            })}
                            required
                            isInvalid={errors?.lastName}
                        />
                        {errors.lastName && (
                            <Form.Control.Feedback type="invalid">
                                {errors?.lastName.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("sponserUsername")} *</Form.Label>
                        <Form.Control
                            id="sponserUsername"
                            type="text"
                            placeholder="username"
                            {...register("sponserUserName", {
                                required: t("this_field_is_required"),
                                pattern: {
                                    value: /^[A-Za-z0-9]+$/,
                                    message: t("invalid_format"),
                                },
                            })}
                            required
                            isInvalid={errors?.sponserUserName}
                        />
                        {errors.sponserUserName && (
                            <Form.Control.Feedback type="invalid">
                                {errors?.sponserUserName.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("phone")} *</Form.Label>
                        <Form.Control
                            id="phone"
                            type="text"
                            placeholder="Phone Number"
                            {...register("phone", {
                                required: t("this_field_is_required"),
                                pattern: {
                                    value: /^[0-9]+$/,
                                    message: t("invalid_format"),
                                },
                            })}
                            required
                            isInvalid={errors?.phone}
                        />
                        {errors.phone && (
                            <Form.Control.Feedback type="invalid">
                                {errors?.phone?.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("email")} *</Form.Label>
                        <Form.Control
                            id="email"
                            type="text"
                            placeholder="Email"
                            {...register("email", {
                                required: t("this_field_is_required"),
                                pattern: {
                                    value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                    message: t("invalid_format"),
                                },
                            })}
                            isInvalid={errors?.email}
                            required
                        />
                        {errors.email && (
                            <Form.Control.Feedback type="invalid">
                                {errors?.email?.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("gender")} *</Form.Label>
                        <select
                            id={"editGender"}
                            name={"editGender"}
                            className={`form-control ${
                                errors["gender"] ? "error-field" : ""
                            }`}
                            {...register("gender", {
                                required: false,
                            })}
                            value={formValues?.gender ?? ""}
                            onChange={async (e) => {
                                const inputValue = e.target.value; // Get the current value of the input field
                                genderChange(inputValue);
                                await trigger("gender");
                            }}
                        >
                            <option value={""}>{"Select gender"}</option>
                            {genderOptions?.map((option, optionIndex) => (
                                <option key={optionIndex} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {errors.gender && (
                            <Form.Control.Feedback type="invalid">
                                {errors?.lastName.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <SubmitButton
                        click={handleSubmit}
                        text="submit"
                        className={"btn btn-primary submit_btn"}
                        // isLoading={transferMutation.isLoading}
                    />
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default CampaignOffcanvas;
