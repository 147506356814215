import API from "../../api/api";
import axios from "axios";
import { BASE_URL, DEFAULT_KEY } from "../../config/config";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response?.data?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const CampaignService = {
    callTiles: async () => {
        return callApi("campaings-tiles");
    },
    callStatement: async (
        page,
        itemsPerPage,
        selectedCategory,
        startDate,
        endDate,
        sortBy,
        direction
    ) => {
        return callApi(
            `get-latest-campaings?page=${page}&perPage=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&type=${selectedCategory}&sortBy=${sortBy}&direction=${direction}`
        );
    },

    callFundTransfer: async (data) => {
        return API.post("fund-transfer", JSON.stringify(data))
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    },

    editCampaign: async (updatedLead) => {
        return API.patch(`edit-campaign-data`, updatedLead)
            .then((response) => Promise.resolve(response?.data?.data))
            .catch((error) => Promise.reject(error));
    },

    addSingleCampaign: async (updatedLead) => {
        return API.post(`add-single-campaign`, updatedLead)
            .then((response) => {
                console.log(response, "22222222222");
                return Promise.resolve(response);
            })
            .catch((error) => {
                console.log(error, "222222222222222222222");
                return Promise.reject(error);
            });
    },

    addFileCampaign: async (updatedLead) => {
        return axios
            .post(`${BASE_URL}/capaign-upload`, updatedLead, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "api-key": DEFAULT_KEY,
                    "access-token": localStorage.getItem("access-token") || "",
                },
            })
            .then((response) => {
                return Promise.resolve(response);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    },
};
