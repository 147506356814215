import { CampaignService } from "../../services/campaign/campaign";

export const CampaignTiles = async () => {
    try {
        const response = await CampaignService.callTiles();
        return response;
    } catch (error) {
        return error.message;
    }
};

export const CampaignStatement = async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate,
    sortBy,
    direction
) => {
    try {
        const response = await CampaignService.callStatement(
            page,
            itemsPerPage,
            selectedCategory,
            startDate,
            endDate,
            sortBy,
            direction
        );
        return response;
    } catch (error) {
        return error.message;
    }
};

export const FundTransfer = async (data) => {
    try {
        const response = await CampaignService.callFundTransfer(data);
        return response;
    } catch (error) {
        return error.message;
    }
};

export const EditCampaign = async (updatedLead) => {
    try {
        const response = await CampaignService.editCampaign(updatedLead);
        return response;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
};

export const AddSingleCampaign = async (updatedLead) => {
    try {
        const response = await CampaignService.addSingleCampaign(updatedLead);
        return response;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
};

export const AddFileCampaign = async (updatedLead) => {
    try {
        console.log("999999999999==", updatedLead);
        const response = await CampaignService.addFileCampaign(updatedLead);
        return response;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
};
