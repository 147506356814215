import React, { useState } from "react";
import TableMain from "../Common/table/TableMain";
import EwalletTableFilter from "../Common/table/EwalletTableFilter";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import CampaignOffcanvas from "./CampaignOffcanvas";

const CampaignTable = (props) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("campaign");
    const [showEdit, setShowEdit] = useState(false);
    const [editData, setEditData] = useState(null);
    const [selectedEarnings, setSelectedEarnings] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
    const [sortOptions, setSortOptions] = useState({
        sortBy: "",
        direction: "",
    });
    const moduleStatus = useSelector(
        (state) => state.dashboard?.appLayout?.moduleStatus
    );
    //------------------------------------------- API -------------------------------------------

    const CampaignData = ApiHook.CallCampaignStatement(
        props.currentPage,
        itemsPerPage,
        selectedEarnings,
        setSelectedEarnings,
        selectedCategory,
        dateRange?.startDate,
        dateRange?.endDate,
        sortOptions.sortBy,
        sortOptions.direction,
        activeTab
    );

    const setApiTab = (tab) => {
        setSelectedEarnings(true);
    };
    const headers = [
        "name",
        "sponser",
        "email",
        "phoneNo",
        "date",
        "status",
        "action",
    ];
    const dropDown = [
        {
            label: "Pending",
            value: 0,
        },
        {
            label: "Completed",
            value: 1,
        },
    ];

    const handleEditClick = (id) => {
        let editData = CampaignData?.data?.data?.filter(
            (item) => item?.id === id
        );
        setEditData(editData);
        setShowEdit(true);
    };
    const handleCloseEdit = () => {
        setEditData(null);
        setShowEdit(false);
    };

    return (
        <div className="ewallet_table_section" style={{ marginTop: "10px" }}>
            <div
                className="table-responsive min-hieght-table"
                style={{ marginBottom: "10px" }}
            >
                {/* {activeTab === "my_earnings" && ( */}
                <>
                    <EwalletTableFilter
                        type={activeTab}
                        setApiTab={setApiTab}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        setDateRange={setDateRange}
                        category={dropDown}
                        setCurrentPage={props.setCurrentPage}
                    />
                    <TableMain
                        headers={headers}
                        data={CampaignData?.data?.data}
                        startPage={1}
                        currentPage={props.currentPage}
                        totalPages={CampaignData?.data?.totalPages}
                        setCurrentPage={props.setCurrentPage}
                        type={"campaign"}
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        activeTab={activeTab}
                        setApiTab={setApiTab}
                        setEpinSortOptions={setSortOptions}
                        handleEditClick={handleEditClick}
                    />
                </>
                {/* )} */}
                <CampaignOffcanvas
                    show={showEdit}
                    handleClose={handleCloseEdit}
                    data={editData}
                />
            </div>
        </div>
    );
};

export default CampaignTable;
